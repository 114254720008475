import Box from '@mui/material/Box';

import 'css/AutoResizableIframe.css';

function TwitchPlayer(props) {
    return (
        <Box>
            <div className="auto-resizable-iframe">
                <div>
                    <iframe src={props.src} title={props.title} frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>
        </Box>
    );
}

export default TwitchPlayer;
