import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import 'css/App.css';
import Forwarder from 'dataObjects/forwarder/Forwarder';
import ContactPage from 'views/ContactPage';
import Home from 'views/Home';
import MusicPage from 'views/MusicPage';
import PhotosPage from 'views/PhotosPage';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ResponsiveAppBar from 'views/ResponsiveAppBar';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
});

function Redirect({url}) {
  return (
    <>
      {window.location.replace(url) && null}
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth={false} sx={{backgroundColor: 'background.default'}}>
        <Container>
          <Link href="/" variant="h2" color="inherit" underline="hover" gutterBottom>
            David Sperling
          </Link>
        </Container>
      </Container>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ResponsiveAppBar />}>
            <Route index element={<Home />} />
            <Route path="about" element={<Container />} />
            <Route path="music" element={<MusicPage />} />
            <Route path="photos" element={<PhotosPage />} />
            <Route path="streams" element={<Redirect url="https://www.twitch.tv/302_Dave" />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="rd/:id" element={<Forwarder />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
