import Box from '@mui/material/Box';

import 'css/AutoResizableIframe.css';

function YouTubePlayer(props) {
    return (
        <Box>
            <div className="auto-resizable-iframe">
                <div>
                    <iframe src={props.src} title={props.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </Box>
    );
}

export default YouTubePlayer;
