import { S3_IMAGE_BUCKET } from "Constant";

export default function PhotoSet({s3FolderPath = '', s3FolderUrl = S3_IMAGE_BUCKET + s3FolderPath, keyPrefix = '', images = []} = {}) {
    const photoSetData = []
    images.forEach((x) => add(x));

    function add({
        file,
        src = s3FolderUrl + file,
        alt,
        width,
        height,
        key = keyPrefix + file || src
    }) {
        photoSetData.push({src, alt, width, height, key});
    }
    
    /**
     * Gets the photoSet data
     * @returns - If no arguments, returns the entire photoSetData
     * - If one argument, returns the data for the item in photoSetData
     * at the specified index
     * - If more than one argument, returns an array of items from photoSetData
     * at the specified indexes in the order those indexes were provided
     */
    function get() {
        if (arguments.length >= 0) {
            return getAll([...arguments]);
        } else if (arguments.length === 1) {
            return photoSetData[arguments[0]];
        }
        return photoSetData;
    }

    /**
     * Gets the photoSet data for an array of indexes.
     * 
     * @param {Array} indexes - A list of photos for which to get the data
     * @returns The data for the photos at the given indexes in the order
     * those indexes were provided
     */
    function getAll(indexes) {
        return indexes.map((index) => photoSetData[index]);
    }

    function getValue(value, ...indexes) {
        if (indexes.length >= 1) {
            return getAllValues(value, indexes);
        } else if (indexes.length === 1) {
            if (indexes[0] instanceof Array) {
                return getAllValues(value, indexes[0])
            } else {
                return photoSetData[indexes[0]]?.[value];
            }
        }
        else return photoSetData.map((photo) => photo[value]);
    }

    function getAllValues(value, indexes) {
        return indexes.map((index) => photoSetData[index]?.[value] ?? null);
    }

    function getSrc(...indexes) {
        return getValue('src', ...indexes);
    }

    function getAlt(...indexes) {
        return getValue('alt', ...indexes);
    }

    function getWidth(...indexes) {
        return getValue('width', ...indexes);
    }

    function getHeight(...indexes) {
        return getValue('height', ...indexes);
    }

    return {
        get,
        getAll,
        getSrc,
        getAlt,
        getWidth,
        getHeight,
    }
}