import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { MillvaleMusicFestival2023 } from 'dataObjects/images/PhotoData';

function PhotosPage() {
    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Photos
                        </Typography>
                        <Typography variant="body1">
                            Various photos taken at performances and music related events.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Millvale Music Festival 2023
                        </Typography>
                        <PhotosPageImageList photos={MillvaleMusicFestival2023.get(0,7,4,5,6,3,2,1)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

function PhotosPageImageList({photos}) {
    const theme = useTheme();
    const smallPage = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <ImageList variant="masonry" cols={smallPage ? 1 : 3} gap={8}>
            {photos.map((photo) => (
                <PhotosPageImageListItem photo={photo} key={photo.key}/>
            ))}
        </ImageList>
    )
}

function PhotosPageImageListItem({photo}) {
    return (
        <ImageListItem>
            <img
            srcSet={`${photo.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${photo.src}?w=248&fit=crop&auto=format`}
            alt={photo.alt}
            loading="lazy"
            />
        </ImageListItem>
    )
}

export default PhotosPage;