import PhotoSet from "./PhotoSet"

const MillvaleMusicFestival2023 = PhotoSet({
    s3FolderPath: 'photos/performances/20230520-millvale/',
    images: [
        {
            file: 'mmf23-01-full-ae.jpg',
            alt: 'David Sperling turns knobs on a Roland TR-8 Drum Machine while performing at Millvale Music Festival 2023.',
            width: 4000,
            height: 1848,
        },
        {
            file: 'mmf23-02-full-ae.jpg',
            alt: 'David Sperling looks to the side while playing keyboards under a green canopy at Millvale Music Festival 2023.',
            width: 1848,
            height: 4000,
        },
        {
            file: 'mmf23-03-full-ae.jpg',
            alt: 'David Sperling plays a synthesizer keyboard and a piano while stading behind a eurorack case at Millvale Music Festival 2023.',
            width: 1848,
            height: 4000,
        },
        {
            file: 'mmf23-04-full-ae.jpg',
            alt: 'David Sperling seen performing under a green canopy at Millvale Music Festival 2023 behind colorful synthesizer patch cables.',
            width: 4000,
            height: 1848,
        },
        {
            file: 'mmf23-05-full-ae.jpg',
            alt: 'David Sperling plays a Fender Jaguar guitar at Millvale Music Festival 2023. Electronic musical instruments surround him.',
            width: 4000,
            height: 1848,
        },
        {
            file: 'mmf23-06-full-ae.jpg',
            alt: 'David Sperlings plays a Yamaha CP-88 Piano at Millvale Music Festival 2023. People behind him listen on wireless headphones.',
            width: 2048,
            height: 1152,
        },
        {
            file: 'mmf23-07-full-ae.jpg',
            alt: 'David Sperling performs on synthesizers alongside two DJs at Millvale Music Festival 2023 while an audience dances.',
            width: 2048,
            height: 1152,
        },
        {
            file: 'mmf23-08-full-ae.jpg',
            alt: 'David Sperling plays a JP-8000 keyboard with his right hand and a drum machine with his left at Millvale Music Festival 2023.',
            width: 2048,
            height: 1152,
        },
    ]
});

export default MillvaleMusicFestival2023;
