import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'

function ExternalLinkList() {
    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="external links">
            <List>
                <ListItem>
                <Typography variant="h5" gutterBottom>
                    Quick Links...
                </Typography>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton href="https://www.twitch.tv/302_dave">
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faTwitch}/>
                        </ListItemIcon>
                        <ListItemText primary="302_Dave on Twitch.tv" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton href="https://linktr.ee/302_Dave">
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faLink}/>
                        </ListItemIcon>
                        <ListItemText primary="Linktree" />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton href="https://youtube.com/playlist?list=PLRywHWY-MwPES9JqNxEPwr7qBgg6GQC4Z">
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faYoutube}/>
                        </ListItemIcon>
                        <ListItemText primary="YouTube playlist of good stuff to watch first." />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton href="/contact#appbar-top">
                        <ListItemIcon>
                        <FontAwesomeIcon icon={faEnvelope}/>
                        </ListItemIcon>
                        <ListItemText primary="Send me a message." />
                    </ListItemButton>
                </ListItem>
            </List>
            </nav>
        </Box>
    );
}

export default ExternalLinkList;