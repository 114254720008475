import Container from '@mui/material/Container';

function ContactPage() {
    return (
        <>
            <Container>
                <iframe
                    title="contactForm"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSckTW7hFEK_w7YikjPbR7WfCNKhZYJ8WADj3tbdI3aCSaU-8Q/viewform?embedded=true"
                    width="100%"
                    height="1080"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0">
                        Loading…
                </iframe>
            </Container>
        </>
    )
}

export default ContactPage;
