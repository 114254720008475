import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ExternalLinkList from 'views/ExternalLinkList';
import YouTubePlayer from 'views/YouTubePlayer'

function Home() {
    return (
        <>
            <Container sx={{marginBottom: '32px'}}>
                <Typography variant="body1">
                    With a rack full of synths, keyboards, and sometimes a guitar, David Sperling builds energetic electronic dance tunes on the fly.
                    His style often gets compared to 90's house and trance music, with a touch of Sega Genesis flavor.
                    He regularly plays live streamed sets under the handle "302_Dave" on Twitch.tv.
                </Typography>
            </Container>
            <Container sx={{marginBottom: '32px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                        <ExternalLinkList />
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <YouTubePlayer title="Intro Playlist" src="https://www.youtube.com/embed/videoseries?list=PLRywHWY-MwPES9JqNxEPwr7qBgg6GQC4Z" />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Home;
