import { useParams } from "react-router-dom";
import FestivalApplications from "./forwarderAddresses/FestivalApplications";

const allAddresses = {};
FestivalApplications.forEach((x) => {allAddresses[x.getId()] = x});

export default function Forwarder() {
    let params = useParams();
    const forward = () => {
        console.log(params);
        console.log(allAddresses);
        if (params.id != null) {
            let url = allAddresses[params.id]?.getUrl();
            window.location.replace(url);
        }
    }
    return (
        <>
            {forward() && null}
        </>
    );
}