/**
 * Links to videos of performances which demonstrate
 * a typical performance for booking.
 */

const DemoVideoUrls = {};

/**
 * Page Title: GSG Showcase : https://gsg.live/showcase (April 14, 2023) -- Synthesizer Jam & Improvised Live Looping
 * Recorded April 14, 2023
 * 
 * This is a Twitch Highlight created from the 2023 GSG Showcase Event.
 * It consists of a roughly 25 minute set with no talking and moving quickly from one groove to another.
 * The link skips the video to the 2 minute 40 second mark where the performance begins.
 * There is some talking before the 2:40 mark, as well as after the performance ends, but the
 *     performance has a clean start and end.
 */
DemoVideoUrls.DV001_SHRIMP_SHOWCASE_TWITCH_SKIP_TO_START = "https://www.twitch.tv/videos/1809486378?t=00h02m40s"

/**
 * The following constants map to a "type" of demo video rather than a specific URL. This way, if
 * a better representative video becomes available, then all links to that type of video can be
 * updated in one place.
 * 
 * Naming Convention:
 * DV_[Location]_[Length]M_[PerformanceStyle]_[Other_info...]
 * 
 * Location - If an external website, then the name of the site.
 *            If on this website, then LOCAL
 *            If it is okay to flip between external and local targets, use ANY
 * 
 */
DemoVideoUrls.DV_TWITCH_30M_GENERAL_NO_TALKING = DemoVideoUrls.DV001_SHRIMP_SHOWCASE_TWITCH_SKIP_TO_START;
DemoVideoUrls.DV_ANY_30M_GENERAL_NO_TALKING = DemoVideoUrls.DV001_SHRIMP_SHOWCASE_TWITCH_SKIP_TO_START;

export default DemoVideoUrls;