import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import TwitchPlayer from 'views/TwitchPlayer';
import YouTubePlayer from 'views/YouTubePlayer';
import { LikeBeforeEmbed } from 'views/SoundCloudEmbeds';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitch } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faSoundcloud } from '@fortawesome/free-brands-svg-icons'

function SectionHeaderIcon(props) {
    return (
        <Box component="span" sx={{ paddingRight: 1.5 }}>
            <small><FontAwesomeIcon icon={props.icon} /></small>
        </Box>
    );
}

function SectionHeader(props) {
    return (
        <Box>
            <Link href={props.href} color="inherit" underline="hover" variant="h5" target="_blank" rel="noopener">
                <SectionHeaderIcon icon={props.icon} />
                {props.label}
            </Link>
        </Box>
    );
}

function MusicPage() {
    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            Music
                        </Typography>
                        <p>
                            My music can be found in several places on the internet, with a slightly different format
                            in each place.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={5} lg={4}>
                        <SectionHeader label="Twitch.tv - Live Streams" href="https://www.twitch.tv/302_Dave" icon={faTwitch} />
                        <p>
                            For the past few years, my primary musical focus has been the performace of live improvised
                            electronic music.
                        </p>
                        <p>
                            During my live sets, I play and record layer after layer of musical loops in real time to
                            build up a groove. Then, I transition into new grooves seamlessly by recording new loops to
                            replace the ones that are already playing, resulting in a constantly evolving vibe over
                            the course of the set.
                        </p>
                        <p>
                            Every set is unique, and is influenced by the audience, so the experince of the music in
                            one of my live performances can be very different than what you get from one of my
                            "produced" tracks.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8}>
                        <TwitchPlayer title="Twitch VOD: The Pre-Summer Broadcast" src="https://player.twitch.tv/?video=1583518643&time=0h4m51s&parent=www.davidsperling.com&parent=davidsperling.com" />
                    </Grid>
                    <Grid container spacing={2} direction="row-reverse">
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={5} lg={4}>
                            <SectionHeader label="YouTube - Recorded Jams" href="https://www.youtube.com/@302_Dave" icon={faYoutube} />
                            <p>
                                My YouTube channel contains mostly pre-recorded videos of performances. There, you can find
                                a few videos which are just archives of past Twitch streams, and some old multi-tracked
                                cover songs.
                            </p>
                            <p>
                                Maybe most notably are a series of thirty-one videos from "Jamuary 2022." This was an online
                                challenge that I participated in to record and publish a performance every day for the
                                month of January. Unlike the music in my live streams, each of these jams is a self contained
                                song, and each one is only a few minutes long.
                            </p>
                            <p>
                                Since these videos were created on a tight schedule, and several were recorded in front of
                                a live online audience, completion was prioritized over perfection. There are certainly
                                some rough edges that can be seen here. Nonetheless, I think there is some interesting
                                stuff here, if I may say so.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={7} lg={8}>
                            <YouTubePlayer title="Jamuary 2022" src="https://www.youtube.com/embed/videoseries?list=PLRywHWY-MwPGhcynvnsvhHNi6weIFLZHQ" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={5} lg={4}>
                        <SectionHeader label="SoundCloud - Produced Music" href="https://soundcloud.com/302_dave" icon={faSoundcloud} />
                        <p>
                            It would be strange if this sort of page didn't have at least a SoundCloud link.
                        </p>
                        <p>
                            As mentioned above, my main focus is currently on live performance, so at the moment,
                            I have not released very many recorded tracks. I am currently working on producing
                            an album of electronic music, but in the meantime, you can listen to a demo track here.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={7} lg={8}>
                        <LikeBeforeEmbed />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default MusicPage;