import LocalFallbackUrls from "./forwarderUrls/LocalFallbackUrls";

/**
 * An object that ties an ID for the Forwarder to a URL.
 * 
 * ID's don't really matter, but I'm generating them by
 * getting the current number of minutes since Dec 1st 2017
 * and converting it to hexadecimal. It gives unique numbers that aren't
 * obviously meaningful to a human, and even if a human figures out the
 * meaning of the ids, there is no harm.
 * 
 * @param {String} id - The id being mapped to a URL
 * @param {String[]} urls - A list of URLS to use for the mapping. The first non-null url in the list will be used.
 * 
 * @returns A new MappedAddress
 */
export default function MappedAddress({id, urls}) {
    function getId() {
        return id;
    }

    function getUrl() {
        for (const url of urls) {
            if (url != null) {
                return url;
            }
            return LocalFallbackUrls.LFB_HOME;
        }
    }

    return {
        getId,
        getUrl
    }
}

