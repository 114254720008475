function LikeBeforeEmbed() {
    return (
        <>
            <iframe title="Like Before Embed" width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1327326472%3Fsecret_token%3Ds-RJwq3VNzNJw&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            <div style={{fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100'}}>
                <a href="https://soundcloud.com/302_dave" title="David Sperling" target="_blank" rel="noreferrer" style={{color: '#cccccc', textDecoration: 'none'}}>David Sperling</a>
                ·
                <a href="https://soundcloud.com/302_dave/like-before-demo/s-RJwq3VNzNJw" title="Like Before (Demo)" target="_blank" rel="noreferrer" style={{color: '#cccccc', textDecoration: 'none'}}>Like Before (Demo)</a>
            </div>
        </>
    )
};

export {LikeBeforeEmbed};
